.web-head{
    font-size: 3.5rem !important;
}

.web-subhead{
    font-size: 1.2rem;
}

@media screen and (max-width: 600px) {
    .web-head{
        font-size: 2.8rem !important;
        padding-bottom: 1.5rem !important;
    }
    .web-subhead{
        font-size: 0.8rem;
    }
}



.web-desc{
    font-size: 1rem;
}

.card-head{
    font-weight: bolder !important;
    letter-spacing: 1.2px;
}

.card-subhead-1{
    font-size: 1rem;
}

.card-subhead-2{
    font-size: 0.9rem;
}

.card-desc-1{
    font-size: 0.8rem;
}

.card-desc-2{
    font-size: 0.7rem;
}

.sec-head{
    font-size: 2rem;
}

/* .sec-subhead{

}

.sec-desc{

} */