.infinity{
    font-size: 1.5rem;
    vertical-align: middle;
    color:#f0a849;
}
.infinity-dark{
    font-size: 1.5rem;
    vertical-align: middle;
    color:#ffce8f;
}

.footer-years{
    font-size: 0.7rem;
}

.made-with{
    color: #858585;
    font-size: 0.7rem;
}

.made-with-dark{
    color: #a5a5a5;
    font-size: 0.7rem;
}

.custom-footer{
    background-color: #f0f0f0;
}

.custom-footer-dark{
    background-color: #272727;
}

.heart-emoji:hover{
    color: red;
    size: 1.4rem;
}