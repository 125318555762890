.experience-card{
    background-color: #fcfcfc  !important;
    transition: 0.4s !important;
}

.experience-card-dark{
    transition: 0.4s !important;
    color: #f9f9f9 !important;
    background-color: #232323 !important;
}

.divider{
    background-color: #e1e1e1;
}
.divider-dark{
    background-color: #a5a5a5 !important;
}

.MuiListItem-gutters{
    padding-left: 0 !important;
}