.custom-navbar{
    background-color: #f0f0f0 !important;
    transition: 0.4s;
}

.custom-navbar-dark{
    background-color: #343434 !important;
    transition: 0.4s;
}

.name-dark{
    color: #fdfdfd;
}

.name{
    color: #4A4A4A;
}

.custom-navbar-burger{
    transition: 0.4s;
}

.navbar-burger.custom-navbar-burger-dark > span{
    background-color: #fafafa;
    transition: 0.4s;
}

.custom-navbar-menu-item{
    transition: 0.4s;
}

.custom-navbar-menu-item-dark > .navbar-item{
    color: #fafafa;
    transition: 0.4s;
}

.custom-navbar-menu-item > a.navbar-item:hover{
    /* background-color: #4A4A4A; */
    color: #e4b77c;
}

.custom-navbar-menu-item-dark > a.navbar-item:active{
    background-color: #4A4A4A;
}

.custom-navbar-menu-item-dark > a.navbar-item:hover{
    background-color: #4A4A4A;
    color: #ffce8f;
}

.is-active.-dark{
    background-color: #383838 !important;
}