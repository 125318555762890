@import url("https://fonts.googleapis.com/css?family=Montserrat:500&display=swap");
body {
  font-family: 'Montserrat', sans-serif;
  /* background-color: #000000;
  background-image: url("https://www.transparenttextures.com/patterns/subtle-zebra-3d.png");
  color:#fafafa; */
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}
.App{
  transition: 0.4s;
}

.App.dark-mode{
  background-color: #181A1B;
  color:#fafafa;
  transition: 0.4s;
}

::-moz-selection, h1::selection, h2::selection, h3::selection, h4::selection, h5::selection, h6::selection  {
  color: #000;
  background: #ffce8f !important;
}
::-webkit-selection, h1::selection, h2::selection, h3::selection, h4::selection, h5::selection, h6::selection  {
  color: #000;
  background: #ffce8f !important;
}
::selection, h1::selection, h2::selection, h3::selection, h4::selection, h5::selection, h6::selection {
  color: #000;
  background: #ffce8f !important;
}



.link {
  text-decoration: none;
  position: relative;
  /* display: block; */
  /* padding: 16px 0; */
  /* margin: 0 12px; */
  /* font-size: 1.2rem; */
  /* text-transform: uppercase; */
  transition: color 0.1s, background-color 0.1s;
  color: #000;
}

/* .link:hover {
  color: #4dd0e1;
}

.link:focus, a:active {
  color: #00bcd4;
} */

.link::before {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  height: 3px;
  width: 100%;
  background-color: #ffce8f;
  -webkit-transform-origin: center top;
          transform-origin: center top;
  -webkit-transform: scale(0, 1);
          transform: scale(0, 1);
  transition: color 0.1s, -webkit-transform 0.2s ease-out;
  transition: color 0.1s, transform 0.2s ease-out;
  transition: color 0.1s, transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

.link:active::before {
  background-color: #ffce8f;
}

.link:hover::before,
.link:focus::before {
  -webkit-transform-origin: center top;
          transform-origin: center top;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.section {
  padding: 3rem 1.5rem;
}

.section.is-small.main-section{
  padding-top: 0;
  padding-bottom: 0;
}

.section.is-small{
  padding-bottom: 3rem;
}

.non-capital-heading{
  text-transform: none; 
  font-size: 0.9rem;
}

.heading{
  font-size: small;
}

.App-link {
  color: #61dafb;
}

@media screen and (max-width: 600px) {
  .section.is-large.intro-section {
    padding: 12rem 1.5rem 15rem 1.5rem;
  }
}

.sec-head{
  transition: 0.4s;
}
.sec-head-dark{
  transition: 0.4s;
  color: #fff4e4;
}

.chip{
  /* border: 1px solid rgba(0, 0, 0, 0.23)!important; */
  /* border-color: rgba(0, 0, 0, 0.23) !important; */
  border-color: #FA8F37 !important;
  margin-top: 7px;
  transition: 0.4s;
}

.chip-dark{
  transition: 0.4s;
  color: #fcfcfc !important;
  margin-top: 7px;
  /* border-color: #fcfcfc !important; */
  border-color: #ffce8f !important;
}

.chip:hover, .chip-dark:hover{
  border: 2px solid;
  transition: 0.2s;
  /* border-color: #ffce8f !important; */
  font-weight: bolder;
}

.MuiSvgIcon-root.accordian-expand-icon, .expand-icon{
  color: #FA8F37 !important;
  transition: 0.2s;
}
.MuiTimelineDot-outlinedGrey{
  color: #ffce8f !important;
}
.MuiTimelineDot-defaultGrey{
  color: #f7e8d4 !important;
}

.MuiSvgIcon-root.darkModeIcon, .MuiTimelineDot-outlinedGrey.darkModeIcon, .MuiTimelineDot-defaultGrey.darkModeIcon, .MuiSvgIcon-root.accordian-expand-icon-dark, .expand-icon-dark{
  color: #ffce8f !important;
  transition: 0.2s;
}