.expand-icon:hover{
    cursor: pointer;
}

.blackIcon{
    color:#4A4A4A;
}

.iconify {
    color: #848484 !important;
    width: 22px; height: 22px;
}

.social{
    color: #848484 !important;
    fill: #4A4A4A;
    filter: grayscale(4);
}

.social.dark-icon{
    color: #848484 !important;
    filter: grayscale(4);
}

.Discord, .Gmail, .Google, .Maps{
    filter: grayscale(4);
}
.Discord-dark, .Gmail-dark, .Google-dark, .Maps-dark{
    filter: grayscale(4);
}

/* .Twitter, .Stack.Overflow{
    filter: grayscale(4);
} */

/* .Twitter.darkIcon, .Stack.Overflow.darkIcon{
    color: #4A4A4A !important;
    fill: #4A4A4A;
    filter: grayscale(4) brightness(90) !important;
} */

.Stack.Overflow:hover{ filter: none;}

.social:hover{filter: none;}

.social.feed:hover       {color:#DC622C;}
.social.CodeChef:hover {color:#6E3B1D !important;}
.social.CodeChef-dark:hover   {color:#b37e5f !important;}
.social.Discord:hover, .social.Discord-dark:hover   {color:#7289DA;}
.social.fa-lastfm:hover   {color:#D51007 !important;}
.social.Facebook:hover   {color:#3B5998;}
.social.GitHub:hover     {color:#040204 !important;}
.social.GitHub-dark:hover     {color:#fcfcfc !important;}
.social.HackerRank:hover, .social.HackerRank-dark:hover   {color:#2CBF5F !important;}
.social.Instagram:hover, .social.Instagram-dark:hover  {color:#2C6A93 !important;}
.social.LeetCode:hover, .social.LeetCode-dark:hover   {color:#F89F1B !important;}
.social.LinkedIn:hover   {color:#04669A !important;}
.social.LinkedIn-dark:hover   {color:#2e91c7 !important;}
.social.OpenStreetMap:hover,    .social.OpenStreetMap-dark:hover   {color:#74A84F !important;}
.social.Reddit:hover,   .social.Reddit-dark:hover   {color:#FF4500 !important;}
.social.Shutterstock:hover,     .social.Shutterstock-dark:hover{color: #EE3625 !important;}
.social.Spotify:hover,  .social.Spotify-dark:hover   {color:#1DB954 !important;}
.social.Strava:hover,   .social.Strava-dark:hover   {color:#FC4C02 !important;}
.social.YouTube:hover,  .social.YouTube-dark:hover    {color:#FF0000 !important;}