.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label{
    background-color: transparent !important;
}
.MuiTableCell-root{
    border-bottom: 0em !important;
}
.css-fpqs8j{
    position: absolute !important;
}

.instructor-name-dark>a, .course-date-dark, .courses-list-dark{
    color: #cbcbcb !important;
}

.instructor-name-dark>a:hover{
    color: #ffffff;
}

.climbing-box-loader-dark{
    background-color: #3d3d3d !important;
}
