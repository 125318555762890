.italics{
    font-style: italic;
}
.lfm-card-credits{
    font-size: 9px;
}
.guni-sheru{
    color: #FA8F37;
    text-decoration: underline dotted;
}
.guni-sheru:hover, .guni-sheru:active{
    color: #df6f13;
    transition: 0.3ms;
    text-decoration: underline dashed;
}
.guni-sheru-dark{
    color: #fce0c4;
    text-decoration: underline dotted;
}
.guni-sheru-dark:hover, .guni-sheru-dark:active{
    color: #ffce8f;
    transition: 0.3ms;
    text-decoration: underline dashed;
}