@import url(https://fonts.googleapis.com/css?family=Montserrat:500&display=swap);
html{
  scrollbar-width: none;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}
::-webkit-scrollbar {
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label{
    background-color: transparent !important;
}
.MuiTableCell-root{
    border-bottom: 0em !important;
}
.css-fpqs8j{
    position: absolute !important;
}

.instructor-name-dark>a, .course-date-dark, .courses-list-dark{
    color: #cbcbcb !important;
}

.instructor-name-dark>a:hover{
    color: #ffffff;
}

.climbing-box-loader-dark{
    background-color: #3d3d3d !important;
}

/* MobileSmall */
@media(max-width: 325px){

}

/* MobileMid */
@media(max-width: 375px){

}

/* MobileFloor */
@media(max-width: 425px){

}

/* TabletFloor */
@media(max-width: 426px){

}

/* TabletMid */
@media(max-width: 768px){

}

/* TabletCeil */
@media(max-width: 1024px){

}

/* LaptopFloor */
@media(max-width: 1025px){

}

/* LaptopCeil */
@media(max-width: 1440px){

}

/* XHDFloor */
@media(max-width: 1441px){

}

/* XHDCeil */
@media(max-width: 4096px){

}

.bordered{
    border-radius: 10px;
    border-style: solid;
}
.bordered-dark{
    border-radius: 10px;
    border-style: solid;
    border-color: #f0f0f0;
}

/* .bordered:hover{
    border: none;
    cursor: grab;
    background: #e0e0e0;
    box-shadow:  20px 20px 60px #bebebe,
                -20px -20px 60px #ffffff;
} */

.institute-box{
    width: 600px;
    border-color: black;
}

.MuiTimelineItem-missingOppositeContent:before{
    content: none !important; 
}

.MuiTimelineContent-root {
    flex: 0 1 !important;
}

.MuiTimelineDot-defaultGrey{
    background-color: #bdbdbd;
    transition: 0.4s;
}

.MuiTimelineDot-defaultGrey.darkModeIcon{
    transition: 0.4s;
    background-color: #343434;
}

.timeline-accordian{
    background-color: #fcfcfc  !important;
    transition: 0.4s;
}

.timeline-accordian-dark{
    transition: 0.4s !important;
    color: #f9f9f9 !important;
    background-color: #232323 !important;
}

.accordian-expand-icon{
    color: black;
}

.accordian-expand-icon-dark{
    color: #bdbdbd;
}

.education-date-dark, .institute-address-dark{
    color: #cbcbcb !important;
}

.map-marker:hover{
    color:#ffce8f !important
}
.experience-card{
    background-color: #fcfcfc  !important;
    transition: 0.4s !important;
}

.experience-card-dark{
    transition: 0.4s !important;
    color: #f9f9f9 !important;
    background-color: #232323 !important;
}

.divider{
    background-color: #e1e1e1;
}
.divider-dark{
    background-color: #a5a5a5 !important;
}

.MuiListItem-gutters{
    padding-left: 0 !important;
}
.italics{
    font-style: italic;
}
.lfm-card-credits{
    font-size: 9px;
}
.guni-sheru{
    color: #FA8F37;
    -webkit-text-decoration: underline dotted;
            text-decoration: underline dotted;
}
.guni-sheru:hover, .guni-sheru:active{
    color: #df6f13;
    transition: 0.3ms;
    -webkit-text-decoration: underline dashed;
            text-decoration: underline dashed;
}
.guni-sheru-dark{
    color: #fce0c4;
    -webkit-text-decoration: underline dotted;
            text-decoration: underline dotted;
}
.guni-sheru-dark:hover, .guni-sheru-dark:active{
    color: #ffce8f;
    transition: 0.3ms;
    -webkit-text-decoration: underline dashed;
            text-decoration: underline dashed;
}
/* MobileSmall */
@media(max-width: 325px){

}

/* MobileMid */
@media(max-width: 375px){

}

/* MobileFloor */
@media(max-width: 425px){

}

/* TabletFloor */
@media(max-width: 426px){

}

/* TabletMid */
@media(max-width: 768px){

}

/* TabletCeil */
@media(max-width: 1024px){

}

/* LaptopFloor */
@media(max-width: 1025px){

}

/* LaptopCeil */
@media(max-width: 1440px){

}

/* XHDFloor */
@media(max-width: 1441px){

}

/* XHDCeil */
@media(max-width: 4096px){

}

.bordered{
    border: 1px;
    border-radius: 12px;
    border-style: solid;
    /* background-color: #000000;
background-image: url("https://www.transparenttextures.com/patterns/psychedelic.png"); */
/* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

.project-card{
    background-color: #fcfcfc  !important;
    transition: 0.4s !important;
}

.project-card-dark{
    transition: 0.4s !important;
    color: #f9f9f9 !important;
    background-color: #232323 !important;
}

.wrap-image {
    position: relative;
}
.wrap-image::before, .wrap-image::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    border: 2px solid #ffce8f;
    border-radius: 10px;
    transition: all 0.25s ease-out;
}
.wrap-image::before {
    background-color: #ffce8f;
    top: -12px;
    left: -12px;
}
.wrap-image::after {
    bottom: -12px;
    right: -12px;
}
.wrap-image:hover::before {
    top: 12px;
    left: 12px;
}
.wrap-image:hover::after {
    bottom: 12px;
    right: 12px;
}

.more-github-projects{
    transition: 0.3ms;
    /* color: #64a5fa; */
    color: #FA8F37;
    -webkit-text-decoration: underline dotted;
            text-decoration: underline dotted;
}

.more-github-projects:hover, .more-github-projects:active{
    color: #df6f13;
    transition: 0.3ms;
    -webkit-text-decoration: underline dashed;
            text-decoration: underline dashed;
}

.more-github-projects-dark{
    color: #fce0c4;
    -webkit-text-decoration: underline dotted;
            text-decoration: underline dotted;
}

.more-github-projects-dark:hover, .more-github-projects-dark:active{
    color: #ffce8f;
    transition: 0.3ms;
    -webkit-text-decoration: underline dashed;
            text-decoration: underline dashed;
}
/* .MuiTimelineItem-missingOppositeContent:before{
    content: none !important; 
}

.MuiTimelineContent-root {
    flex: 0 !important;
} */

.expand-icon:hover{
    cursor: pointer;
}

.blackIcon{
    color:#4A4A4A;
}

.iconify {
    color: #848484 !important;
    width: 22px; height: 22px;
}

.social{
    color: #848484 !important;
    fill: #4A4A4A;
    -webkit-filter: grayscale(4);
            filter: grayscale(4);
}

.social.dark-icon{
    color: #848484 !important;
    -webkit-filter: grayscale(4);
            filter: grayscale(4);
}

.Discord, .Gmail, .Google, .Maps{
    -webkit-filter: grayscale(4);
            filter: grayscale(4);
}
.Discord-dark, .Gmail-dark, .Google-dark, .Maps-dark{
    -webkit-filter: grayscale(4);
            filter: grayscale(4);
}

/* .Twitter, .Stack.Overflow{
    filter: grayscale(4);
} */

/* .Twitter.darkIcon, .Stack.Overflow.darkIcon{
    color: #4A4A4A !important;
    fill: #4A4A4A;
    filter: grayscale(4) brightness(90) !important;
} */

.Stack.Overflow:hover{ -webkit-filter: none; filter: none;}

.social:hover{-webkit-filter: none;filter: none;}

.social.feed:hover       {color:#DC622C;}
.social.CodeChef:hover {color:#6E3B1D !important;}
.social.CodeChef-dark:hover   {color:#b37e5f !important;}
.social.Discord:hover, .social.Discord-dark:hover   {color:#7289DA;}
.social.fa-lastfm:hover   {color:#D51007 !important;}
.social.Facebook:hover   {color:#3B5998;}
.social.GitHub:hover     {color:#040204 !important;}
.social.GitHub-dark:hover     {color:#fcfcfc !important;}
.social.HackerRank:hover, .social.HackerRank-dark:hover   {color:#2CBF5F !important;}
.social.Instagram:hover, .social.Instagram-dark:hover  {color:#2C6A93 !important;}
.social.LeetCode:hover, .social.LeetCode-dark:hover   {color:#F89F1B !important;}
.social.LinkedIn:hover   {color:#04669A !important;}
.social.LinkedIn-dark:hover   {color:#2e91c7 !important;}
.social.OpenStreetMap:hover,    .social.OpenStreetMap-dark:hover   {color:#74A84F !important;}
.social.Reddit:hover,   .social.Reddit-dark:hover   {color:#FF4500 !important;}
.social.Shutterstock:hover,     .social.Shutterstock-dark:hover{color: #EE3625 !important;}
.social.Spotify:hover,  .social.Spotify-dark:hover   {color:#1DB954 !important;}
.social.Strava:hover,   .social.Strava-dark:hover   {color:#FC4C02 !important;}
.social.YouTube:hover,  .social.YouTube-dark:hover    {color:#FF0000 !important;}
/*
 * From:
 * https://webomnizz.com/how-to-create-a-toggle-switch-button-in-react/
*/

.wrg-toggle {
    touch-action: pan-x;
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
}

.wrg-toggle-input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.wrg-toggle-check, .wrg-toggle-uncheck {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    opacity: 0;
    transition: opacity .25s ease;
}
.wrg-toggle-check {
    left: 8px;
}
.wrg-toggle-uncheck {
    opacity: 1;
    right: 10px;
}

.wrg-toggle-uncheck span, 
.wrg-toggle-check span {
    align-items: center;
    display: flex;
    height: 10px;
    justify-content: center;
    position: relative;
    width: 10px;
}

.wrg-toggle-container{
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #4d4d4d;
    transition: all .2s ease;
}

.wrg-toggle-circle{
    transition: all .5s cubic-bezier(.23,1,.32,1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #4d4d4d;
    border-radius: 50%;
    background-color: #fafafa;
    box-sizing: border-box;
    transition: all .25s ease;
}

.wrg-toggle--checked .wrg-toggle-check{
    opacity: 1;
}
.wrg-toggle--checked .wrg-toggle-uncheck {
    opacity: 0;
}
.wrg-toggle--checked .wrg-toggle-circle {
    left: 27px;
}
.custom-navbar{
    background-color: #f0f0f0 !important;
    transition: 0.4s;
}

.custom-navbar-dark{
    background-color: #343434 !important;
    transition: 0.4s;
}

.name-dark{
    color: #fdfdfd;
}

.name{
    color: #4A4A4A;
}

.custom-navbar-burger{
    transition: 0.4s;
}

.navbar-burger.custom-navbar-burger-dark > span{
    background-color: #fafafa;
    transition: 0.4s;
}

.custom-navbar-menu-item{
    transition: 0.4s;
}

.custom-navbar-menu-item-dark > .navbar-item{
    color: #fafafa;
    transition: 0.4s;
}

.custom-navbar-menu-item > a.navbar-item:hover{
    /* background-color: #4A4A4A; */
    color: #e4b77c;
}

.custom-navbar-menu-item-dark > a.navbar-item:active{
    background-color: #4A4A4A;
}

.custom-navbar-menu-item-dark > a.navbar-item:hover{
    background-color: #4A4A4A;
    color: #ffce8f;
}

.is-active.-dark{
    background-color: #383838 !important;
}
.infinity{
    font-size: 1.5rem;
    vertical-align: middle;
    color:#f0a849;
}
.infinity-dark{
    font-size: 1.5rem;
    vertical-align: middle;
    color:#ffce8f;
}

.footer-years{
    font-size: 0.7rem;
}

.made-with{
    color: #858585;
    font-size: 0.7rem;
}

.made-with-dark{
    color: #a5a5a5;
    font-size: 0.7rem;
}

.custom-footer{
    background-color: #f0f0f0;
}

.custom-footer-dark{
    background-color: #272727;
}

.heart-emoji:hover{
    color: red;
    size: 1.4rem;
}
body {
  font-family: 'Montserrat', sans-serif;
  /* background-color: #000000;
  background-image: url("https://www.transparenttextures.com/patterns/subtle-zebra-3d.png");
  color:#fafafa; */
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}
.App{
  transition: 0.4s;
}

.App.dark-mode{
  background-color: #181A1B;
  color:#fafafa;
  transition: 0.4s;
}
::-webkit-selection, h1::selection, h2::selection, h3::selection, h4::selection, h5::selection, h6::selection  {
  color: #000;
  background: #ffce8f !important;
}
::selection, h1::selection, h2::selection, h3::selection, h4::selection, h5::selection, h6::selection {
  color: #000;
  background: #ffce8f !important;
}



.link {
  text-decoration: none;
  position: relative;
  /* display: block; */
  /* padding: 16px 0; */
  /* margin: 0 12px; */
  /* font-size: 1.2rem; */
  /* text-transform: uppercase; */
  transition: color 0.1s, background-color 0.1s;
  color: #000;
}

/* .link:hover {
  color: #4dd0e1;
}

.link:focus, a:active {
  color: #00bcd4;
} */

.link::before {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  height: 3px;
  width: 100%;
  background-color: #ffce8f;
  -webkit-transform-origin: center top;
          transform-origin: center top;
  -webkit-transform: scale(0, 1);
          transform: scale(0, 1);
  transition: color 0.1s, -webkit-transform 0.2s ease-out;
  transition: color 0.1s, transform 0.2s ease-out;
  transition: color 0.1s, transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

.link:active::before {
  background-color: #ffce8f;
}

.link:hover::before,
.link:focus::before {
  -webkit-transform-origin: center top;
          transform-origin: center top;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.section {
  padding: 3rem 1.5rem;
}

.section.is-small.main-section{
  padding-top: 0;
  padding-bottom: 0;
}

.section.is-small{
  padding-bottom: 3rem;
}

.non-capital-heading{
  text-transform: none; 
  font-size: 0.9rem;
}

.heading{
  font-size: small;
}

.App-link {
  color: #61dafb;
}

@media screen and (max-width: 600px) {
  .section.is-large.intro-section {
    padding: 12rem 1.5rem 15rem 1.5rem;
  }
}

.sec-head{
  transition: 0.4s;
}
.sec-head-dark{
  transition: 0.4s;
  color: #fff4e4;
}

.chip{
  /* border: 1px solid rgba(0, 0, 0, 0.23)!important; */
  /* border-color: rgba(0, 0, 0, 0.23) !important; */
  border-color: #FA8F37 !important;
  margin-top: 7px;
  transition: 0.4s;
}

.chip-dark{
  transition: 0.4s;
  color: #fcfcfc !important;
  margin-top: 7px;
  /* border-color: #fcfcfc !important; */
  border-color: #ffce8f !important;
}

.chip:hover, .chip-dark:hover{
  border: 2px solid;
  transition: 0.2s;
  /* border-color: #ffce8f !important; */
  font-weight: bolder;
}

.MuiSvgIcon-root.accordian-expand-icon, .expand-icon{
  color: #FA8F37 !important;
  transition: 0.2s;
}
.MuiTimelineDot-outlinedGrey{
  color: #ffce8f !important;
}
.MuiTimelineDot-defaultGrey{
  color: #f7e8d4 !important;
}

.MuiSvgIcon-root.darkModeIcon, .MuiTimelineDot-outlinedGrey.darkModeIcon, .MuiTimelineDot-defaultGrey.darkModeIcon, .MuiSvgIcon-root.accordian-expand-icon-dark, .expand-icon-dark{
  color: #ffce8f !important;
  transition: 0.2s;
}
.web-head{
    font-size: 3.5rem !important;
}

.web-subhead{
    font-size: 1.2rem;
}

@media screen and (max-width: 600px) {
    .web-head{
        font-size: 2.8rem !important;
        padding-bottom: 1.5rem !important;
    }
    .web-subhead{
        font-size: 0.8rem;
    }
}



.web-desc{
    font-size: 1rem;
}

.card-head{
    font-weight: bolder !important;
    letter-spacing: 1.2px;
}

.card-subhead-1{
    font-size: 1rem;
}

.card-subhead-2{
    font-size: 0.9rem;
}

.card-desc-1{
    font-size: 0.8rem;
}

.card-desc-2{
    font-size: 0.7rem;
}

.sec-head{
    font-size: 2rem;
}

/* .sec-subhead{

}

.sec-desc{

} */
