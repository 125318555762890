/* MobileSmall */
@media(max-width: 325px){

}

/* MobileMid */
@media(max-width: 375px){

}

/* MobileFloor */
@media(max-width: 425px){

}

/* TabletFloor */
@media(max-width: 426px){

}

/* TabletMid */
@media(max-width: 768px){

}

/* TabletCeil */
@media(max-width: 1024px){

}

/* LaptopFloor */
@media(max-width: 1025px){

}

/* LaptopCeil */
@media(max-width: 1440px){

}

/* XHDFloor */
@media(max-width: 1441px){

}

/* XHDCeil */
@media(max-width: 4096px){

}

.bordered{
    border-radius: 10px;
    border-style: solid;
}
.bordered-dark{
    border-radius: 10px;
    border-style: solid;
    border-color: #f0f0f0;
}

/* .bordered:hover{
    border: none;
    cursor: grab;
    background: #e0e0e0;
    box-shadow:  20px 20px 60px #bebebe,
                -20px -20px 60px #ffffff;
} */

.institute-box{
    width: 600px;
    border-color: black;
}

.MuiTimelineItem-missingOppositeContent:before{
    content: none !important; 
}

.MuiTimelineContent-root {
    flex: 0 !important;
}

.MuiTimelineDot-defaultGrey{
    background-color: #bdbdbd;
    transition: 0.4s;
}

.MuiTimelineDot-defaultGrey.darkModeIcon{
    transition: 0.4s;
    background-color: #343434;
}

.timeline-accordian{
    background-color: #fcfcfc  !important;
    transition: 0.4s;
}

.timeline-accordian-dark{
    transition: 0.4s !important;
    color: #f9f9f9 !important;
    background-color: #232323 !important;
}

.accordian-expand-icon{
    color: black;
}

.accordian-expand-icon-dark{
    color: #bdbdbd;
}

.education-date-dark, .institute-address-dark{
    color: #cbcbcb !important;
}

.map-marker:hover{
    color:#ffce8f !important
}