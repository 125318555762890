/* MobileSmall */
@media(max-width: 325px){

}

/* MobileMid */
@media(max-width: 375px){

}

/* MobileFloor */
@media(max-width: 425px){

}

/* TabletFloor */
@media(max-width: 426px){

}

/* TabletMid */
@media(max-width: 768px){

}

/* TabletCeil */
@media(max-width: 1024px){

}

/* LaptopFloor */
@media(max-width: 1025px){

}

/* LaptopCeil */
@media(max-width: 1440px){

}

/* XHDFloor */
@media(max-width: 1441px){

}

/* XHDCeil */
@media(max-width: 4096px){

}

.bordered{
    border: 1px;
    border-radius: 12px;
    border-style: solid;
    /* background-color: #000000;
background-image: url("https://www.transparenttextures.com/patterns/psychedelic.png"); */
/* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

.project-card{
    background-color: #fcfcfc  !important;
    transition: 0.4s !important;
}

.project-card-dark{
    transition: 0.4s !important;
    color: #f9f9f9 !important;
    background-color: #232323 !important;
}

.wrap-image {
    position: relative;
}
.wrap-image::before, .wrap-image::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    border: 2px solid #ffce8f;
    border-radius: 10px;
    transition: all 0.25s ease-out;
}
.wrap-image::before {
    background-color: #ffce8f;
    top: -12px;
    left: -12px;
}
.wrap-image::after {
    bottom: -12px;
    right: -12px;
}
.wrap-image:hover::before {
    top: 12px;
    left: 12px;
}
.wrap-image:hover::after {
    bottom: 12px;
    right: 12px;
}

.more-github-projects{
    transition: 0.3ms;
    /* color: #64a5fa; */
    color: #FA8F37;
    text-decoration: underline dotted;
}

.more-github-projects:hover, .more-github-projects:active{
    color: #df6f13;
    transition: 0.3ms;
    text-decoration: underline dashed;
}

.more-github-projects-dark{
    color: #fce0c4;
    text-decoration: underline dotted;
}

.more-github-projects-dark:hover, .more-github-projects-dark:active{
    color: #ffce8f;
    transition: 0.3ms;
    text-decoration: underline dashed;
}
/* .MuiTimelineItem-missingOppositeContent:before{
    content: none !important; 
}

.MuiTimelineContent-root {
    flex: 0 !important;
} */
